import React, { useEffect, useState, useRef } from "react";
import "./page.css";
import { PC } from "../../hooks/useMedia";
import PlentyIcon from "../../assets/images/plentyIcon.png";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Page = () => {
    const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    window.addEventListener("resize", appHeight);
    appHeight();

    let userAgent = navigator.userAgent;
    let visitedAt = new Date().getTime();
    const searchParams = new URLSearchParams(window.location.search);
    const referral = searchParams.get("referral") || "M100001";
    const appLink = "https://plenty.onelink.me/yTQT/faeajz4x";
    // const hostDeepLink = `${appLink}?deep_link=com.metapocket&plentyReferral=${referral}&deep_link_value=${referral}`;
    const hostDeepLink = `${appLink}?plentyReferral=${referral}&deep_link_value=${referral}`;
    const iosDeepLink = `${appLink}?af_force_deeplink=true&af_dp=com.truesuccesssystem.MetaPocket:/2/1/?plentyReferral=${referral}&deep_link_value=${referral}`;
    const androidDeepLink = `${appLink}?af_force_deeplink=true&plentyReferral=${referral}`;


    useEffect(() => {
        // window.location.href = hostDeepLink;
        // window.location.href = iosDeepLink;
        if (!userAgent.match(/iPhone|iPad|iPod/)){
            setTimeout(function () {
                window.location.href = hostDeepLink;
            }, 200);

        }
    }, []);

    const goToApp = () => {
        window.location.href = hostDeepLink;

        // if (userAgent.match(/iPhone|iPad|iPod/)) {
        //     window.location.href = `${appLink}?pid=plenty_platform&af_dp=com.metapocket://`;
        //     // window.location.href = `https://plenty.onelink.me/yTQT/af_cp_lp=true&faeajz4x?af_force_deeplink=true&af_dp=com.truesuccesssystem.MetaPocket://2/1/${referral}?plentyReferral=${referral}`
        // } else {
        //     // window.location.href = "https://play.google.com/store/apps/details?id=com.metapocket&hl=en_US";
        //     window.location.href = `${appLink}?af_force_deeplink=true&plentyReferral=${referral}`;
        // }
    };

    return (
        <div className="link-page">
            <div className="link-inner-wrap">
                <div className="link-inner">
                    <div className="logo-wrap">
                        <img src={PlentyIcon} alt="plenty" />
                    </div>
                    <p>Connecting with Plenty</p>
                    { !userAgent.match(/iPhone|iPad|iPod/) &&
                        <ColorRing
                            visible={true}
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{ marginTop: "10px" }}
                            wrapperClass="color-ring-wrapper"
                            colors={["#ccc", "#ccc", "#ccc", "#ccc", "#ccc"]}
                        />
                    }
                </div>
                {/* <div className='store-btn' onClick={()=>window.location.href= `https://plenty.onelink.me/yTQT/faeajz4x?plentyReferral=${referral}`}> */}
                {/* {userAgent.match(/iPhone|iPad|iPod/) && (
                    <div className="store-btn black" onClick={goToApp}>
                        <p>Connect To Plenty</p>
                    </div>
                )} */}
                {/* <div className="store-btn black" onClick={goToApp}>
                    <p>{referral}</p>
                </div> */}
                <div className="store-btn" onClick={goToApp}>
                    <p>Open Plenty</p>
                </div>
            </div>
        </div>
    );
};

export default Page;
