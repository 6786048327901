import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEnglish from "./eng";
import translationKorea from "./kor";
import translationChina from "./chn";
import translationJapan from "./jpn";
import translationPhn from "./phn";
import translationVnm from "./vnm";
import translationTha from "./tha";
import translationMng from "./mng";

const userLanguage = window.navigator.language || window.navigator.userLanguage;

const resources = {
    kor: {
        translation: translationKorea,
    },
    eng: {
        translation: translationEnglish,
    },
    chn: {
        translation: translationChina,
    },
    jpn: {
        translation: translationJapan,
    },
    phn: {
        translation: translationPhn,
    },
    vnm: {
        translation: translationVnm,
    },
    tha: {
        translation: translationTha,
    },
    mng: {
        translation: translationMng,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem("language") || userLanguage || process.env.REACT_APP_LANG,
    fallbackLng: process.env.REACT_APP_LANG,
});

export default i18n;
