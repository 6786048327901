import React, { useState, useEffect } from "react";
import "./Header.css";
import { IconLogo, IconLanguage, IconMenu, IconClose, IconArrowDown, IconLogoPlenty } from "../../constants/icons";
import { LANGUAGE_LIST, APP_DOWN_BUTTON_LIST } from "../../constants/datas";
import { Mobile, PC, useCustomMediaQuery } from "../../hooks/useMedia";
import useLanguage from "../../hooks/useLanguage";
import { useAnimate, stagger } from "framer-motion";
import apkSrc from "../../assets/download/Plenty.apk";

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });

const motionHeaderValues = {
    motion: { after: "inset(0% 0% 0% 0% round 0px)", before: "inset(10% 50% 90% 50% round 0px)" },
    transition: {
        type: "spring",
        bounce: 0,
        duration: 0.5,
    },
};
const motionHeaderValuesWeb = {
    motion: { after: "inset(0% 0% 0% 0% round 10px)", before: "inset(10% 50% 90% 50% round 10px)" },
    transition: {
        type: "spring",
        bounce: 0,
        duration: 0.5,
    },
};
const motionSubValues = (isOpen = null) => {
    return {
        motion: { after: { opacity: 1, scale: 1, filter: "blur(0px)" }, before: { opacity: 0, scale: 0.3, filter: "blur(20px)" } },
        transition: {
            duration: 0.2,
            delay: isOpen ? staggerMenuItems : 0,
        },
    };
};

const CustomHeader = ({ onChangeSlide, isDownloadPage, isOpen, setIsOpen, isLanguageOpen, setIsLanguageOpen, appLink }) => {
    const { langState, changeLanguage } = useLanguage();

    const [activeIdx, setActiveIdx] = useState(0);

    const [scope, animate] = useAnimate(); //header 애니메이션을 위한 ref
    const { isPc } = useCustomMediaQuery();

    // const [isOpen, setIsOpen] = useState(false);
    // const [isLanguageOpen, setIsLanguageOpen] = useState(false);

    const onClickLogo = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const onCloseMenu = () => {
        setIsOpen(!isOpen);
        setIsLanguageOpen(false);
    };

    const onChangeLanguage = (type) => {
        changeLanguage(type);
        setIsOpen(false);
        setIsLanguageOpen(false);
    };

    useEffect(() => {
        if (!isPc) {
            animate(
                "ul",
                {
                    clipPath: isOpen ? motionHeaderValues.motion.after : motionHeaderValues.motion.before,
                },
                motionHeaderValues.transition
            );
            animate("li", isOpen ? motionSubValues().motion.after : motionSubValues().motion.before, motionSubValues(isOpen).transition);
        }
    }, [isOpen]);

    useEffect(() => {
        let headerClassName = isPc ? ".lang-drop-wrapper" : ".side-lang-title";
        let subClassName = isPc ? ".lang-drop-item" : ".lang-btn-item";

        if (!isPc) {
            animate(".arrow", { rotate: isLanguageOpen ? 180 : 0 }, { duration: 0.2 });
            animate(
                headerClassName,
                {
                    clipPath: isLanguageOpen ? motionHeaderValues.motion.after : motionHeaderValues.motion.before,
                },
                motionHeaderValues.transition
            );
        } else {
            animate(
                headerClassName,
                {
                    clipPath: isLanguageOpen ? motionHeaderValuesWeb.motion.after : motionHeaderValuesWeb.motion.before,
                },
                motionHeaderValuesWeb.transition
            );
        }

        animate(subClassName, isLanguageOpen ? motionSubValues().motion.after : motionSubValues().motion.before, motionSubValues(isLanguageOpen).transition);
    }, [isLanguageOpen]);

    const onClickButton = (idx) => {
        setActiveIdx(idx < 6 ? 0 : 1);
        onChangeSlide(idx);
    };

    const downloadApk = () => {
        // window.open(apkSrc);
        window.open(process.env.PUBLIC_URL + "/apk/Plenty.apk");
    };

    useEffect(() => {
        setActiveIdx(isDownloadPage ? 1 : 0);
    }, [isDownloadPage]);

    return (
        <header>
            <div className="header-container" ref={scope}>
                <div className="header-wrapper">
                    <div className="header-left-wrapper" onClick={onClickLogo}>
                        <a href="#section1" onClick={() => setActiveIdx(0)}>
                            <div className="logo-icon">
                                <IconLogoPlenty />
                                {/* <IconLogo /> */}
                            </div>
                        </a>
                    </div>
                    <div className="header-right-wrapper">
                        <PC>
                            <button type="button" className={`nav-text font-large ${activeIdx === 0 ? "active font-sb" : ""}`} onClick={() => onClickButton(0)}>
                                About
                            </button>
                            <button type="button" className={`nav-text font-large ${activeIdx === 1 ? "active font-sb" : ""}`} onClick={() => onClickButton(6)}>
                                Download
                            </button>
                            <button type="button" className={`language-box ${isLanguageOpen ? "active" : ""}`} onClick={() => setIsLanguageOpen(!isLanguageOpen)}>
                                <div className="lang-icon">
                                    <IconLanguage />
                                </div>
                                <p className="font-small">{LANGUAGE_LIST.find((item) => item.type === langState).title}</p>
                            </button>
                            <div className="lang-drop-container">
                                <div
                                    className="lang-drop-wrapper"
                                    style={{
                                        pointerEvents: isLanguageOpen ? "auto" : "none",
                                        clipPath: "inset(10% 50% 90% 50% round 10px)",
                                    }}
                                >
                                    {LANGUAGE_LIST.map((item, idx) => (
                                        <button
                                            className={`lang-drop-item ${item.type === langState ? "active font-sb" : ""}`}
                                            type="button"
                                            key={`lang-item-${idx}`}
                                            onClick={() => onChangeLanguage(item.type)}
                                        >
                                            {item.title}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </PC>
                        <Mobile>
                            <button type="button" className="menu-box" onClick={onCloseMenu}>
                                {isOpen ? <IconClose /> : <IconMenu />}
                            </button>
                        </Mobile>
                    </div>
                </div>
                <Mobile>
                    <ul
                        className="mobile-side-nav-bar"
                        style={{
                            pointerEvents: isOpen ? "auto" : "none",
                            clipPath: "inset(10% 50% 90% 50%)",
                            display: isOpen ? "block" : "none",
                            borderBottom: isOpen && !isLanguageOpen ? "1px solid #DBE5FF" : "",
                        }}
                    >
                        <li>
                            <div className="side-top-title">
                                <h4>Plenty {langState === "kor" ? "다운로드" : "Download"}</h4>
                            </div>
                            {langState === "chn" ? (
                                <div className="side-sub-title">
                                    <a onClick={downloadApk} style={{ cursor: "pointer" }}>
                                        Apk
                                    </a>
                                </div>
                            ) : (
                                <div className="side-sub-title">
                                    {/* <a onClick={downloadApk} style={{ cursor: "pointer" }}>
                                        Apk
                                    </a> */}
                                    {APP_DOWN_BUTTON_LIST.map((item, idx) => (
                                        <a
                                            key={`app-down-list-item-${idx}`}
                                            href={item.type === "G" ? null : appLink}
                                            onClick={() => item.type === "G" && downloadApk()}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {item.type === "G" ? "Android Apk" : item.title}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </li>
                        <li>
                            <div className="side-top-title">
                                <div onClick={() => setIsLanguageOpen(!isLanguageOpen)} className="lang-drop-button">
                                    <button type="button" onClick={() => setIsLanguageOpen(true)}>
                                        <h4>{langState === "kor" ? "언어선택" : "Language"}</h4>
                                    </button>
                                    <div className="arrow">
                                        <IconArrowDown />
                                    </div>
                                </div>
                                <p>{LANGUAGE_LIST.find((item) => item.type === langState).title}</p>
                            </div>
                        </li>
                    </ul>
                    <div
                        className="side-sub-title side-lang-title"
                        style={{
                            marginTop: "-1px",
                            pointerEvents: isLanguageOpen ? "auto" : "none",
                            clipPath: "inset(10% 50% 90% 50%)",
                            display: isLanguageOpen ? "block" : "none",
                            borderBottom: isOpen && isLanguageOpen ? "1px solid #DBE5FF" : "",
                        }}
                    >
                        <div className="divier" />
                        {LANGUAGE_LIST.map((item, idx) => (
                            <button className={`lang-btn-item ${item.type === langState ? "active font-sb" : ""}`} type="button" key={`lang-item-${idx}`} onClick={() => onChangeLanguage(item.type)}>
                                {item.title}
                            </button>
                        ))}
                    </div>
                </Mobile>
            </div>
        </header>
    );
};

export default CustomHeader;
