import React, { forwardRef } from "react";

const IconBackgroundLineMobile = forwardRef(({ isInView = false }, ref) => {
    return (
        <div className="backgroudn-line">
            <svg viewBox="0 0 360 379" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    ref={ref}
                    d="M-167.71 322.046C-127.728 250.806 -27.4461 101.445 30.747 125.614C103.488 155.825 23.016 289.234 112.068 282.9C187.123 277.562 153.644 81.0662 329.524 9.9196"
                    stroke="url(#paint0_linear_3187_90514)"
                    strokeWidth="2"
                    strokeLinejoin="round"
                    className={isInView ? "line-ani" : ""}
                />
                <path d="M337.745 5.98328L331.08 15.8739L326 3.90731L337.745 5.98328Z" fill="white" className={isInView ? "line-ani-triangle" : ""} />
                <defs>
                    <linearGradient id="paint0_linear_3187_90514" x1="-179.021" y1="290.968" x2="311.578" y2="8.54926" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0001" stopColor="white" stopOpacity="0.1" />
                        <stop offset="0.485" stopColor="#6D82FF" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
});

export default IconBackgroundLineMobile;
