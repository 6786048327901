import HOME2_BOX_IMG1 from "../assets/images/home2-wallet-1.png";
import HOME2_BOX_IMG2 from "../assets/images/home2-wallet-2.png";
import HOME2_BOX_IMG3 from "../assets/images/home2-wallet-3.png";
import HOME6_SLIDE_ICON1 from "../assets/images/home6-slide-icon-01.png";
import HOME6_SLIDE_ICON2 from "../assets/images/home6-slide-icon-02.png";
import HOME6_SLIDE_ICON3 from "../assets/images/home6-slide-icon-03.png";
import HOME6_SLIDE_ICON4 from "../assets/images/home6-slide-icon-04.png";
import HOME6_SLIDE_ICON5 from "../assets/images/home6-slide-icon-05.png";
import HOME6_SLIDE_ICON6 from "../assets/images/home6-slide-icon-06.png";
import HOME6_SLIDE_ICON7 from "../assets/images/home6-slide-icon-07.png";
import HOME6_SLIDE_ICON8 from "../assets/images/home6-slide-icon-08.png";
import HOME4_PIZE0 from "../assets/images/home4-symbol0.png";
import HOME4_PIZE1 from "../assets/images/home4-symbol1.png";
import HOME4_PIZE2 from "../assets/images/home4-symbol2.png";
import HOME4_PIZE3 from "../assets/images/home4-symbol3.png";
import HOME4_PIZE4 from "../assets/images/home4-symbol4.png";
import HOME4_PIZE5 from "../assets/images/home4-symbol5.png";
import HOME4_PIZE6 from "../assets/images/home4-symbol6.png";
// import HOME4_PIZE7 from "../assets/images/home4-symbol7.png";
// import HOME4_PIZE8 from "../assets/images/home4-symbol8.png";
import HOME7_QR_APPLE from "../assets/images/home7-qr-apple.png";
// import HOME7_QR_GOOGLE from "../assets/images/home7-qr-google.png";
import HOME7_QR_GOOGLE from "../assets/images/home7-coming-google.png";
// import HOME7_BUTTON_GOOGLE from "../assets/images/home7-btn-google.png";
import HOME7_BUTTON_GOOGLE from "../assets/images/home7-coming-google-m.png";
import HOME7_BUTTON_APPLE from "../assets/images/home7-btn-appstore.png";

export const HOME2_BOX_LIST = [
    {
        id: "1",
        title: "home2-box1-title",
        subTitle: "home2-box1-sub-title",
        image: HOME2_BOX_IMG1,
    },
    {
        id: "2",
        title: "home2-box2-title",
        subTitle: "home2-box2-sub-title",
        image: HOME2_BOX_IMG2,
    },
    {
        id: "3",
        title: "home2-box3-title",
        subTitle: "home2-box3-sub-title",
        image: HOME2_BOX_IMG3,
    },
];

export const APP_DOWN_BUTTON_LIST = [
    {
        id: "1",
        title: "Android",
        type: "G",
        qr: HOME7_QR_GOOGLE,
        button: HOME7_BUTTON_GOOGLE,
        link: "https://play.google.com/store/apps/details?id=com.metapocket",
    },
    {
        id: "2",
        title: "iOS",
        type: "A",
        qr: HOME7_QR_APPLE,
        button: HOME7_BUTTON_APPLE,
        link: "https://apps.apple.com/kr/app/metabucket/id6447240070",
    },
];

export const HOME6_SLIDE_LIST = [HOME6_SLIDE_ICON1, HOME6_SLIDE_ICON2, HOME6_SLIDE_ICON3, HOME6_SLIDE_ICON4, HOME6_SLIDE_ICON5, HOME6_SLIDE_ICON6, HOME6_SLIDE_ICON7, HOME6_SLIDE_ICON8];
export const HOME4_PRIZE_LIST = [
    {
        id: "0",
        color: "#FFCF95",
        image: HOME4_PIZE0,
    },
    {
        id: "1",
        color: "#CFD6FF",
        image: HOME4_PIZE1,
    },
    {
        id: "2",
        color: "#CEEBD1",
        image: HOME4_PIZE2,
    },
    {
        id: "3",
        color: "#D3E5FF",
        image: HOME4_PIZE3,
    },
    // {
    //     id: "4",
    //     color: "#FFEDCE",
    //     image: HOME4_PIZE4,
    // },
    // {
    //     id: "5",
    //     color: "#EEE9ED",
    //     image: HOME4_PIZE5,
    // },
    // {
    //     id: "6",
    //     color: "#BEE1E5",
    //     image: HOME4_PIZE6,
    // },
    // {
    //     id: "7",
    //     color: "#FFDDDD",
    //     image: HOME4_PIZE7,
    // },
    // {
    //     id: "8",
    //     color: "#E5EBE9",
    //     image: HOME4_PIZE8,
    // },
];

export const LANGUAGE_LIST = [
    {
        type: "eng",
        title: "English",
    },
    {
        type: "kor",
        title: "한국어",
    },
    {
        type: "chn",
        title: "中國語",
    },
    {
        type: "jpn",
        title: "日本語",
    },
    {
        type: "phn",
        title: "Pilipinas",
    },
    {
        type: "vnm",
        title: "tiếng Việt",
    },
    {
        type: "tha",
        title: "ภาษาไทย",
    },
    // {
    //     type: "mng",
    //     title: "Монгол хэл",
    // },
];
