import React from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { HOME6_SLIDE_LIST } from "../../constants/datas";
import HOME5_CAT from "../../assets/images/home5-cat.png";
import { PC, Mobile } from "../../hooks/useMedia";
import "swiper/css";

const SixthSection = ({ t, Trans, langState }) => {
    return (
        <div className="center-wrapper">
            <div className="six-content-wrapper">
                <PC>
                    <motion.div className="img-cat" whileInView={{ scale: [0.7, 1, 0.5, 1] }} transition={{ times: [0, 0.1, 0.9, 1], bounce: 0.5, type: "spring", repeat: Infinity, repeatDelay: 1 }}>
                        <img src={HOME5_CAT} alt="메타버킷 경품 고양이"></img>
                    </motion.div>
                </PC>
                <div className="text-wrapper">
                    <h1 className={`title ${langState}`}>
                        <PC>{t("home6-title")}</PC>
                        <Mobile>{t("home6-title-m")}</Mobile>
                    </h1>
                    <div className="slide-present-wrapper">
                        <div className="gradient-wrapper"></div>
                        <Swiper
                            centeredSlides
                            autoplay={{
                                delay: 1000,
                                disableOnInteraction: false,
                            }}
                            direction={"vertical"}
                            loop
                            modules={[Autoplay]}
                            className="mySwiper"
                            spaceBetween={40}
                            slidesPerView={2}
                        >
                            {HOME6_SLIDE_LIST.map((image, idx) => (
                                <SwiperSlide key={`slide-item-${idx}`}>
                                    <div className="slide-image-item">
                                        <img src={image} alt={`home-slide-present-img-${image}`} />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <h1 className={`title ${langState}`}>
                        <PC>
                            <Trans i18nKey="home6-title2" components={{ span: <span /> }} />
                        </PC>
                        <Mobile>
                            <Trans i18nKey="home6-title2-m" components={{ span: <span /> }} />
                        </Mobile>
                    </h1>
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default SixthSection;
