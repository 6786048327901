import React, { useState } from "react";
import "./Footer.css";
import { IconLogoTwitter } from "../../constants/icons";
import Privacy_policy_en from "../../assets/download/plenty_privacy_policy_en.pdf";
import Privacy_policy_ko from "../../assets/download/plenty_privacy_policy_ko.pdf";
import Terms_of_use_en from "../../assets/download/plenty_terms_of_use_en.pdf";
import Terms_of_use_ko from "../../assets/download/plenty_terms_of_use_ko.pdf";

const Footer = ({ t, Trans, langState }) => {
    const [activeIdx, setActiveIdx] = useState(0);

    return (
        <footer className="footer-wrapper">
            <div className="footer-left-wrapper">
                <div className="footer-left-top">
                    <a className={`${langState}`} href={langState === "kor" ? Privacy_policy_ko : Privacy_policy_en} target="_blank">
                        {t("footer-privacy")}
                    </a>
                    <a className={`${langState}`} href={langState === "kor" ? Terms_of_use_ko : Terms_of_use_en} target="_blank">
                        {t("footer-terms")}
                    </a>
                    <p>
                        {`Email : `}
                        <a href="mailto:beplenty.cs@gmail.com">beplenty.cs@gmail.com</a>
                    </p>
                </div>
                <div className="footer-left-bottom">
                    <p>Copyright @2024. IDAS. Inc</p>
                </div>
                <div className="footer-left-translate">
                    <p className={`${langState}`}>
                        <Trans i18nKey="footer-translate" components={{ p: <p /> }} />
                    </p>
                </div>
            </div>
            {/* <div className="footer-right-wrapper">
                <a className="img-twitter" href="https://twitter.com/MetaBucketApp" target="_blank">
                    <IconLogoTwitter />
                </a>
            </div> */}
        </footer>
    );
};

export default Footer;
