import React, { useCallback } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { langAtomState } from "../stores/atom";

const useLanguage = () => {
    const [langState, setLangState] = useRecoilState(langAtomState);
    const { t, i18n } = useTranslation();

    const changeLanguage = useCallback(
        (lang) => {
            setLangState(lang);
            i18n.changeLanguage(lang);
            localStorage.setItem("lang", lang);
        },
        [langState]
    );
    return { changeLanguage, t, langState };
};

export default useLanguage;
