import React, { forwardRef } from "react";

const IconBackgroundLine = forwardRef(({ isInView = false }, ref) => {
    return (
        <div className="backgroudn-line">
            <svg width="80%" height="100%" viewBox="0 0 1639 546" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    ref={ref}
                    d="M-23 369.209C156.29 214.246 576.956 -94.2789 711.324 29.6913C879.283 184.654 528.298 469.279 776.808 540.569C986.258 600.653 1089.73 30.1913 1638.6 10.696"
                    stroke="url(#paint0_linear_2869_42176)"
                    strokeWidth="2"
                    strokeLinejoin="round"
                    className={isInView ? "line-ani" : ""}
                />
                <defs>
                    <linearGradient id="paint0_linear_2869_42176" x1="-23" y1="273" x2="1591.61" y2="-8.20041" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0001" stopColor="white" stopOpacity="0.1" />
                        <stop offset="0.485" stopColor="#6D82FF" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
});

export default IconBackgroundLine;
