import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimate } from "framer-motion";
import { HOME2_BOX_LIST } from "../../constants/datas";
import { motionContainer, slideUpMotion } from "../../constants/motions";
import HOME2_BACKGROUND from "../../assets/images/home2-bg.png";
import { Trans } from "react-i18next";

const SecondSection = ({ t, langState }) => {
    const motionBoxContainer = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                delayChildren: 1.5,
                staggerChildren: 0.3,
            },
        },
    };

    const view = useRef();
    const isInView = useInView(view);

    const [background, animate] = useAnimate();

    useEffect(() => {
        if (isInView) {
            animate(background.current, { opacity: 1 }, { duration: 0.5, delay: 1 });
        }
    }, [isInView]);

    return (
        <div className="common-bg-container">
            <div className="common-bg-wrapper" style={{ opacity: 0, backgroundImage: `url(${HOME2_BACKGROUND})` }} ref={background} />
            <div className="center-wrapper">
                <motion.div className="center-top-wrapper" variants={motionContainer} initial="hidden" whileInView="show" viewport={{ once: true }} ref={view}>
                    <motion.h2 className={`title ${langState}`} variants={slideUpMotion}>
                        <Trans i18nKey="home2-title" components={{ span: <span /> }} />
                    </motion.h2>
                    <motion.h3 className="sub-title" variants={slideUpMotion}>
                        {t("home2-sub-title")}
                    </motion.h3>
                </motion.div>
                <div className="center-bottom-wrapper">
                    <motion.div className="wallet-box-list" variants={motionBoxContainer} initial="hidden" whileInView="show" viewport={{ once: true }}>
                        {HOME2_BOX_LIST.map((item, idx) => (
                            <motion.div className="wallet-box-item" key={`home2-box-list-${idx}`} variants={slideUpMotion}>
                                <div className={`box-top-wrapper ${langState} ${idx === 1 ? "second" : ""}`}>
                                    <h4 className={`${langState}`}>{t(`${item.title}`)}</h4>
                                    <p className={` ${langState}`}>{t(`${item.subTitle}`)}</p>
                                </div>
                                <div className="box-bottom-wrapper">
                                    <div className="img-box-icon">
                                        <img src={item.image} alt={`월렛박스${idx}`} />
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default SecondSection;
