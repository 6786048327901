import React, { useState, useRef, useEffect } from "react";
import { motion, useInView } from "framer-motion";
import { motionContainer, slideUpMotion, slideUpContainer, scaleMotion, HiddenandScaleMotion, scaleFadeAndHiddenMotion, scaleFadeMotion } from "../../constants/motions";
import { Swiper, SwiperSlide } from "swiper/react";
// import { useCountUp } from 'react-countup';
import CountUp, { useCountUp } from 'react-countup';
import { Pagination } from "swiper/modules";
import HOME5_PHONE from "../../assets/images/home5-minepet-phone1.png";
import HOME5_PTE_01 from "../../assets/images/chara-01.png";
import HOME5_PTE_03 from "../../assets/images/chara-03.png";
import HOME5_PTE_05 from "../../assets/images/chara-05.png";
import "swiper/css/pagination";

const FifthSectionMinePet = ({ t, Trans, langState }) => {
    const viewRef = useRef(null);
    const isInView = useInView(viewRef);
    const countUpRef = useRef(null);

    const [onSwiper, setOnSwiper] = useState(null);
    const [firstCount, setFirstCount] = useState(true);
    const [isTextChanged, setIsTextChanged] = useState(false);
    const { start, pauseResume, reset, update } = useCountUp({
        ref: countUpRef,
        start:0,
        end:770.12,
        duration:1,
        separator:",",
        decimals:3,
        decimal:".",
        onReset: () => {},
        onUpdate: ({ pauseResume, reset, start }) => {},
        onPauseResume: () => {},
        onStart: ({ pauseResume }) => {},
        onEnd: ({ pauseResume }) => {},
      });
    

    const onChangeTextMotion = (definition) => {
        setIsTextChanged(definition === "show");
    };

    const onChangePhone = (definition) => {
        if (definition === "show") {
            onSwiper?.slideNext();
        }
    };

    const bounceAnimate = {
        hidden: { opacity: 1 },
        show: { opacity: 1, scale: [1, 1.1, 1], time: [0, 0.5, 1] },
        // show: { opacity: 1, scale: 1, transition: { type: "spring", stiffness: 500 } },
    };
    // const upCount = () => {
    //     useCountUp({ ref: 'counter', end: 1234567 });
    //     return <span className='usdt-text' id="counter" />;
    //   };

    useEffect(() => {
        if (!isInView) onSwiper?.slidePrev();
        if(isInView && firstCount) {
            setFirstCount(false)
            // update(254.45)
            setTimeout(() => { 
                start()
            },  2300);
            setTimeout(() => { 
            update(4523.35)
            }, 2800);
            setTimeout(() => { 
                update(12452.318)
            }, 4500);
        }
    }, [isInView]);

    return (
        <div className="half-wrapper">
            <div className="half-left-wrapper" ref={viewRef}>
                <motion.div variants={motionContainer} initial="hidden" whileInView="show" viewport={{ once: true }} onAnimationComplete={onChangeTextMotion}>
                    <motion.p className={`top-title ${langState}`} variants={slideUpMotion}>
                        {t("home5-top-minepet-title")}
                    </motion.p>
                    <motion.h2 className={`title ${langState}`} variants={slideUpMotion}>
                        <Trans i18nKey="home5-minepet-title" components={{ span: <span /> }} />
                    </motion.h2>
                </motion.div>
            </div>
            <motion.div className="half-right-wrapper" variants={slideUpContainer} initial="hidden" viewport={{ once: true }} whileInView={isTextChanged ? "show" : "hidden"}>
                <div className="img-phone">
                    <img className="img-phone-minepet" src={HOME5_PHONE} alt="마인펫 핸드폰 이미지" />
                </div>
                {/* <motion.div className="img-phone-button" variants={bounceAnimate} onAnimationComplete={onChangePhone}> */}
                <div  className='img-phone-usdt'>
                    {/* <p className='usdt-text'>12,452.318</p> */}
                    {/* <CountUp
                        start={475.039}
                        end={770.12}
                        duration={2.75}
                        separator=","
                        decimals={3}
                        decimal="."
                        onEnd={() => console.log('Ended! 👏')}
                        onStart={() => console.log('Started! 💨')}
                        >
                        {({ countUpRef, start }) => (
                            <div>
                            <span  className='usdt-text' ref={countUpRef} />
                            </div>
                        )}
                        </CountUp> */}
                        <span  className='usdt-text' ref={countUpRef} />
                    <p className='usdt-sub-text'>USDT</p>
                </div>
                {/* <motion.div> */}
                    <motion.img src={HOME5_PTE_01} alt="마인펫 이미지 01" className='img-pet'  variants={HiddenandScaleMotion(0.5)} />
                {/* </motion.div>
                <motion.div className='img-pet'  > */}
                    <motion.img src={HOME5_PTE_03} alt="마인펫 이미지 03" className='img-pet' variants={scaleFadeAndHiddenMotion(1.8)}/>
                {/* </motion.div>
                <motion.div className='img-pet' > */}
                    <motion.img src={HOME5_PTE_05} alt="마인펫 이미지 05"  className='img-pet'  variants={scaleFadeMotion(3.8)}  />
                {/* </motion.div> */}
                {/* </motion.div> */}
                
            </motion.div>
        </div>
    );
};

export default FifthSectionMinePet;
