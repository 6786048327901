import React, { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";
import { motionContainer, slideUpMotion } from "../../constants/motions";
import { Mobile, PC, useCustomMediaQuery } from "../../hooks/useMedia";
import HOME3_CARD1 from "../../assets/images/home3-card1.png";
import HOME3_CARD2 from "../../assets/images/home3-card2.png";
import HOME3_CARD3 from "../../assets/images/home3-card3.png";
import HOME3_CARD_COIN from "../../assets/images/home3-card-coin.png";
import HOME3_LEFT_COIN from "../../assets/images/home3-left-coin.png";
import HOME3_RIGHT_COIN from "../../assets/images/home3-right-coin.png";
import HOME3_RIGHT_COIN_BUCKET from "../../assets/images/home3-right-coin-bucket.png";
import IconBackgroundLine from "../../assets/images/icons/IconBackgroundLine";
import IconBackgroundLineMobile from "../../assets/images/icons/IconBackgroundLineMobile";

const ThirdSction = ({ t, langState }) => {
    const lineRef = useRef();
    const { isPc } = useCustomMediaQuery();
    const viewRef = useRef(null);
    const isInView = useInView(viewRef);

    const boxItem1Motion = { pc: { rotate: -15, x: 20, y: 0 }, mobile: { rotate: 0, x: 20, y: 0 } };
    const boxItem2Motion = { pc: { rotate: 0, x: 0, y: -80 }, mobile: { rotate: 15, x: -30, y: -30 } };
    const boxItem3Motion = { pc: { rotate: 15, x: -20, y: 0 }, mobile: { rotate: -15, x: 30, y: -60 } };

    const boxItemMotions = [{ ...boxItem1Motion }, { ...boxItem2Motion }, { ...boxItem3Motion }];

    const boxSlideUpMotion = (idx) => {
        const res = isPc ? "pc" : "mobile";

        const { rotate, x, y } = boxItemMotions[idx][res];

        return {
            hidden: { opacity: 0, scale: 0.4, rotate: `0deg`, y: -30, x: 0 },
            show: { opacity: 1, scale: 1, rotate: `${rotate}deg`, y: y, x: x },
        };
    };

    return (
        <>
            <PC>
                <IconBackgroundLine ref={lineRef} isInView={isInView} />
            </PC>
            <Mobile>
                <IconBackgroundLineMobile ref={lineRef} isInView={isInView} />
            </Mobile>
            <motion.div className={`half-wrapper ${langState}`} variants={motionContainer} initial="hidden" whileInView="show" viewport={{ once: true }}>
                <div className="half-left-wrapper">
                    <motion.p className={`top-title  ${langState}`} variants={slideUpMotion} ref={viewRef}>
                        {t("home3-top-title")}
                    </motion.p>
                    <motion.h2 className={`title ${langState}`} variants={slideUpMotion}>
                        <PC>{t("home3-title")}</PC>
                        <Mobile>{t("home3-title-m")}</Mobile>
                    </motion.h2>
                </div>
                <div className="half-right-wrapper">
                    <div className="join-right-box-wrapper">
                        <div className="join-box-list">
                            <motion.div className="join-box-item box-item1" variants={boxSlideUpMotion(0)}>
                                <div className="box-item-left-image-wrapper">
                                    <div className="float-image img-left-coin-bucket">
                                        <img src={HOME3_LEFT_COIN} alt="코인상자이미지" />
                                    </div>
                                </div>
                                <div className="box-item-top">
                                    <div className="img-box-item">
                                        <img src={HOME3_CARD1} alt="회원가입 폰 이미지" />
                                    </div>
                                </div>
                                <div className="box-item-middle">
                                    <h5 className={`${langState}`}>{t("home3-box-item1")}</h5>
                                </div>
                                <div className="box-item-bottom">
                                    <div className="box-item-coin">
                                        <img src={HOME3_CARD_COIN} alt="코인 아이콘" />
                                    </div>
                                    <h4>1,000</h4>
                                </div>
                            </motion.div>
                            <motion.div className="join-box-item box-item2" variants={boxSlideUpMotion(1)}>
                                <div className="box-item-top">
                                    <div className="img-box-item">
                                        <img src={HOME3_CARD2} alt="회원가입 출석체크 이미지" />
                                    </div>
                                </div>
                                <div className="box-item-middle">
                                    <h5 className={`${langState}`}>{t("home3-box-item2")}</h5>
                                </div>
                                <div className="box-item-bottom">
                                    <div className="box-item-coin">
                                        <img src={HOME3_CARD_COIN} alt="코인 아이콘" />
                                    </div>
                                    <h4>50</h4>
                                </div>
                            </motion.div>
                            <motion.div className="join-box-item box-item3" variants={boxSlideUpMotion(2)}>
                                <div className="box-item-top">
                                    <div className="img-box-item">
                                        <img src={HOME3_CARD3} alt="회원가입 손뼉 이미지" />
                                    </div>
                                </div>
                                <div className="box-item-middle">
                                    <h5 className={`${langState}`}>{t("home3-box-item3")}</h5>
                                </div>
                                <div className="box-item-bottom">
                                    <div className="box-item-coin">
                                        <img src={HOME3_CARD_COIN} alt="코인 아이콘" />
                                    </div>
                                    <h4>300</h4>
                                </div>
                                <div className="box-item-right-image-wrapper">
                                    <div className="float-image img-right-coin">
                                        <img src={HOME3_RIGHT_COIN} alt="오른쪽 코인버킷 이미지" />
                                    </div>
                                    <div className="float-image img-right-coin-bucket">
                                        <img src={HOME3_RIGHT_COIN_BUCKET} alt="오른쪽 코인 아이콘 이미지" />
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                    <PC>
                        <motion.h2 className={`title ${langState}`} variants={slideUpMotion}>
                            {t("home3-title2")}
                        </motion.h2>
                    </PC>
                </div>
            </motion.div>
        </>
    );
};

export default ThirdSction;
