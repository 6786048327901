import { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import { ScrollToTop } from "./components/ScrollToTop";
import useLanguage from "./hooks/useLanguage";
import Home from "./pages/Home";
import Page from "./pages/Page/Page2";

const App = () => {
    const REACT_APP_HOST = process.env.REACT_APP_API_URL;
    const REACT_APP_GET_USER_IP = process.env.REACT_APP_GET_USER_IP;
    const { langState, changeLanguage } = useLanguage();

    const getUserIp = async () => {
        try {
            const { data } = await axios.get(`${REACT_APP_GET_USER_IP}`);

            return data.IPv4;
        } catch (ex) {
            const { data } = await axios.get(`https://api.ipify.org?format=json`);
            return data.ip;
        }
    };

    const timestampNow = () => {
        return Math.floor(+new Date() / 1000);
    };

    const setVisitHistory = async () => {
        const userIpGet = await getUserIp();
        const body = {
            user_ip: userIpGet,
            timestamp: timestampNow(),
        };
        try {
            const { data } = await axios.post(`${REACT_APP_HOST}/pocket/v1/visit/history`, body);
            if (data.result === "Y") {
            }
        } catch (ex) {
            // console.log(ex);
        }
    };

    const getLocation = async () => {
        try {
            const browserLang = navigator.language || navigator.userLanguage;
            const langCode = browserLang.substr(0, 2);
            if (langCode === "zh" || langCode === "zh-Hans" || langCode === "zh-Hant") {
                changeLanguage("chn");
                window.location.href = "https://cn.beplenty.com/";
            } else {
                //     changeLanguage("kor");
                const lang = localStorage.getItem("lang");
                if (lang != null) {
                    changeLanguage(lang);
                } else {
                    changeLanguage("eng");
                }
            }
            // else if (data.country_code === "KR") {
            //     changeLanguage("kor");
            // }
        } catch (ex) {
            // console.log("");
        }
    };

    useEffect(() => {
        // changeLanguage("chn");
        getLocation();
        // setVisitHistory();
    }, []);

    const customModalStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.56)",
            
            zIndex: '20 !important',
        },
        content: {
            position: "relative",
            // top: "50%",
            // left: "50%",
            // transform: "translateX(-50%)",
            inset: "0",
            border: "none",
            maxWidth: "540px",
            margin: "0 auto",
            height: "100%",
            backgroundColor: "transparent",
            padding: "0",
            zIndex: '20',
            // height: "fit-content",
            // overflow: "auto",
        },
    };

    return (
        <BrowserRouter>
            <ScrollToTop />
           
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/page" element={<Page />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
