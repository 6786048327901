import React, { useState, useEffect, useRef, Fragment } from "react";
import { motion, useInView, useAnimate } from "framer-motion";
import { motionContainer, slideUpMotion } from "../../constants/motions";
import { APP_DOWN_BUTTON_LIST } from "../../constants/datas";
import { useCustomMediaQuery, PC, Mobile } from "../../hooks/useMedia";
import HOME7_PHONE from "../../assets/images/home7-phone2.png";
import ENG_HOME7_PHONE from "../../assets/images/eng-home7-phone.png";
import HOME7_BACKGROUND from "../../assets/images/home7-bg.jpg";
import { IconApkBtnWeb, IconApkBtnMobile } from "../../constants/icons";
import apkSrc from "../../assets/download/Plenty.apk";

const SeventhSection = ({ t, Trans, langState, appLink }) => {
    const [isTextChanged, setIsTextChanged] = useState(false);

    const { isPc } = useCustomMediaQuery();
    const view = useRef();
    const isInView = useInView(view);

    const [background, animate] = useAnimate();

    const downloadApk = () => {
        window.open(process.env.PUBLIC_URL + "/apk/Plenty.apk");
    };

    useEffect(() => {
        if (isInView) {
            animate(background.current, { opacity: 1 }, { duration: 0.5, delay: 0.5 });
        }
    }, [isInView]);

    return (
        <div className="common-bg-container" id="section7">
            <div className="common-bg-wrapper" style={{ backgroundImage: `url(${HOME7_BACKGROUND})` }} ref={background} />
            <div className="center-wrapper">
                <motion.div
                    className="center-top-wrapper"
                    variants={motionContainer}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true }}
                    onAnimationComplete={(e) => setIsTextChanged(e === "show")}
                    ref={view}
                >
                    <motion.h1 className={`title ${langState}`} variants={slideUpMotion}>
                        <Trans i18nKey="home7-title" components={{ span: <span /> }} />
                    </motion.h1>
                    <motion.p className={`font-large ${langState}`} variants={slideUpMotion}>
                        {t("home7-sub-title")}
                    </motion.p>
                    {langState === "chn" ? (
                        <motion.div className="app-down-list" variants={slideUpMotion}>
                            <PC>
                                <IconApkBtnWeb onClick={downloadApk} />
                            </PC>
                            <Mobile>
                                <IconApkBtnMobile className="apk-down-mobile" onClick={downloadApk} />
                            </Mobile>
                        </motion.div>
                    ) : (
                        <motion.div className="app-down-list" variants={slideUpMotion}>
                            <div style={{ transform: isPc ? "scale(1.4)" : "scale(1)", marginBottom: isPc ? "20px" : "0px" }}>
                                <IconApkBtnMobile onClick={downloadApk} />
                            </div>
                            <div className={"app-down-web-flex"}>
                                {APP_DOWN_BUTTON_LIST.map((item, idx) => (
                                    <Fragment key={`qr-box-list-${idx}`}>
                                        <PC>
                                            <div className="app-down-box-item">
                                                <p>{item.title}</p>
                                                <div className="qr-image">
                                                    <img src={item.qr} alt={`qr이미지-${item.type}`} />
                                                </div>
                                            </div>
                                        </PC>
                                        <Mobile>
                                            <a
                                                href={item.type === "G" ? null : appLink}
                                                onClick={() => item.type === "G" && alert("Coming soon!")}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="app-down-button"
                                            >
                                                {/* <div className="app-down-button"> */}
                                                <img src={item.button} alt={`앱다운로드 버튼-${item.type}`} />
                                                {/* </div> */}
                                            </a>
                                        </Mobile>
                                    </Fragment>
                                ))}
                            </div>
                        </motion.div>
                    )}
                </motion.div>
                <motion.div className="center-bottom-wrapper" variants={motionContainer} initial="hidden" viewport={{ once: true }} whileInView={isTextChanged ? "show" : "hidden"}>
                    <motion.div className="center-image-wrapper" initial={{ opacity: 1, y: 10 }} animate={{ y: 0 }} transition={{ repeat: Infinity, duration: 2, repeatType: "reverse" }}>
                        <div className="img-phone">
                            <img src={langState === "kor" ? HOME7_PHONE : ENG_HOME7_PHONE} alt="메타버킷 다운로드 휴대폰 이미지" />
                        </div>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
};

export default SeventhSection;
