import React, { useState, useEffect } from "react";
import "./Modal.css";
import Close from "../../assets/images/close.png";
import useLanguage from '../../hooks/useLanguage';
import { Trans } from 'react-i18next';
import ModalImgENG from './../../assets/images/modal-minepet-eng.png'
import ModalImgKOR from './../../assets/images/modal-minepet-kor.png'
import ModalImgMobile from './../../assets/images/modal-minepet-eng-mobile.png'
import ModalImgMobileKOR from './../../assets/images/modal-minepet-kor-mobile.png'
import { Mobile, PC } from '../../hooks/useMedia';

const Modal = ({onClose, onClick}) => {
    
    const { t, langState } = useLanguage();
    return (
        <>
        <div className='modal-background'></div>
        <div className='modal-wrap-main' onClick={onClick}>
            <Mobile>
                <div className='modal-wrap-mobile' style={{backgroundImage: `url(${langState === "kor" ? ModalImgMobileKOR :ModalImgMobile})`, width: '100%'}}>
                    <img src={Close} alt='close' onClick={onClose} className='modal-close-icon'/> 
                </div>
            </Mobile>
            <PC>
                <div className='modal-wrap' style={{backgroundImage: `url(${langState === "kor" ? ModalImgKOR : ModalImgENG})`, width: '100%'}}>
                    <img src={Close} alt='close' onClick={onClose} className='modal-close-icon'/> 
                </div>      
            </PC>
        
        </div>
        </>
    );
};

export default Modal;