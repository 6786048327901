import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import { HOME4_PRIZE_LIST } from "../../constants/datas";
import { motionContainer, slideUpMotion, scaleMotion } from "../../constants/motions";
import HOME4_PHONE from "../../assets/images/home4-phone2.png";
import HOME4_PHONE_BOX1 from "../../assets/images/home4-phone-box1.png";
import HOME4_PHONE_BOX2 from "../../assets/images/home4-phone-box2.png";
import ENG_HOME4_PHONE from "../../assets/images/eng-home4-phone.png";
import ENG_HOME4_PHONE_BOX1 from "../../assets/images/eng-home4-phone-box1.png";
import ENG_HOME4_PHONE_BOX2 from "../../assets/images/eng-home4-phone-box2.png";
import { PC } from "../../hooks/useMedia";

const FourthSection = ({ t, Trans, langState }) => {
    const [isTextChanged, setIsTextChanged] = useState(false);
    const [isPrizeChanged, setIsPrizeChanged] = useState(false);

    const prizeMotion = {
        opacity: 1,
        // transition: {
        //     delay: 0.5,
        // },
    };

    const phoneContainer = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                delayChildren: 0.5,
                staggerChildren: 0.5,
            },
        },
    };

    const phoneContainer2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                delayChildren: 0.5,
                staggerChildren: 0.5,
            },
        },
    };

    const onChangeMotion = (definition, setChangedState) => {
        setChangedState(definition === "show");
    };

    useEffect(() => {
        return () => {
            setIsPrizeChanged(false);
            setIsTextChanged(false);
        };
    }, []);

    return (
        <div className="half-wrapper">
            <div className="half-left-wrapper">
                <motion.div
                    variants={phoneContainer}
                    initial="hidden"
                    animate={isPrizeChanged ? "show" : "hidden"}
                    onAnimationComplete={(e) => onChangeMotion(e, setIsTextChanged)}
                    style={{ position: "relative" }}
                >
                    <motion.div className="img-phone" variants={slideUpMotion}>
                        <img src={langState === "kor" ? HOME4_PHONE : ENG_HOME4_PHONE} alt="룰렛 경품 핸드폰 이미지" />
                        <motion.div className="phone-box-item-wrapper" variants={phoneContainer2} initial="hidden" animate={isTextChanged ? "show" : "hidden"}>
                            <motion.div className="img-phone-box1" variants={scaleMotion()}>
                                <img src={langState === "kor" ? HOME4_PHONE_BOX1 : ENG_HOME4_PHONE_BOX1} alt="핸드폰 럭키버킷 박스"></img>
                            </motion.div>
                            <motion.div className="img-phone-box2" variants={scaleMotion()}>
                                <img src={langState === "kor" ? HOME4_PHONE_BOX2 : ENG_HOME4_PHONE_BOX2} alt="핸드폰 룰렛 박스"></img>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </div>
            <div className="half-right-wrapper">
                <motion.div
                    className="title-content-wrapper"
                    variants={motionContainer}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true }}
                    onAnimationComplete={(e) => onChangeMotion(e, setIsPrizeChanged)}
                >
                    <motion.p className={`top-title ${langState}`} variants={slideUpMotion}>
                        {t("home4-top-title")}
                    </motion.p>
                    <motion.h2 className={`title ${langState}`} variants={slideUpMotion}>
                        <Trans i18nKey="home4-title" components={{ span: <span /> }} />
                    </motion.h2>
                </motion.div>
            </div>
            <motion.div className="prize-list-wrapper" initial={motionContainer.hidden} animate={isTextChanged ? prizeMotion : motionContainer.hidden}>
                <Marquee speed={isTextChanged ? 100 : 0} autoFill>
                    {HOME4_PRIZE_LIST.map((item, idx) => (
                        <img className={`prize-item-box ${idx === 1 || idx === 7 ? "prize-size-small" : idx === 3 ? "prize-size-big" : ""}`} key={`prize-list-item-${idx}`} src={item.image} />
                    ))}
                </Marquee>
            </motion.div>
        </div>
    );
};

export default FourthSection;
