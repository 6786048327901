import React, { Fragment } from "react";
import { motion } from "framer-motion";
import { IconMeta, IconBucket, IconCircle } from "../../constants/icons";
import HOME1_BUCKET from "../../assets/images/home1-coin-bucket.png";
import HOME1_COIN from "../../assets/images/home1-coin.png";
import HOME1_PHONE from "../../assets/images/home1-phone.png";
import ENG_HOME1_PHONE from "../../assets/images/eng-home1-phone.png";
import { Mobile, PC } from "../../hooks/useMedia";

const MainSection = ({ Trans, langState }) => {
    const motionContainer = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                delayChildren: 0.5,
                // staggerDirection: 2,
                staggerChildren: 0.5,
            },
        },
    };

    const motionDefault = {
        hidden: { opacity: 0 },
        show: { opacity: 1 },
    };

    const slideUpItem = {
        hidden: { ...motionDefault.hidden, y: 30 },
        show: { ...motionDefault.show, y: 0 },
    };

    return (
        <motion.div className="center-wrapper" variants={motionContainer} initial="hidden" animate="show">
            <motion.div variants={slideUpItem} className="center-top-wrapper">
                <h1 className={`title ${langState}`}>
                    {/* <PC> */}
                    <Trans i18nKey="home1-title" components={{ span: <span /> }} />
                    {/* </PC> */}
                    {/* <Mobile>
                        <Trans i18nKey="home1-title-m" components={{ span: <span /> }} />
                    </Mobile> */}
                </h1>
            </motion.div>
            <div className="center-bottom-wrapper">
                <div className="center-image-wrapper">
                    {/* <motion.div className="float-image ic-meta" variants={motionDefault}>
                        <IconCircle />
                        <IconMeta />
                    </motion.div>
                    <motion.div className="float-image ic-bucket" variants={motionDefault}>
                        <IconBucket />
                    </motion.div> */}
                    <PC>
                        <motion.div className="float-image ic-circle-web" variants={motionDefault}>
                            <IconCircle />
                        </motion.div>
                    </PC>
                    <Mobile>
                        <motion.div className="float-image ic-circle-mobile" variants={motionDefault}>
                            <IconCircle />
                        </motion.div>
                    </Mobile>

                    <motion.div className="img-phone" variants={slideUpItem}>
                        <img src={langState === "kor" ? HOME1_PHONE : ENG_HOME1_PHONE} alt="metabucket-phone-img" />
                    </motion.div>
                    {/* <motion.div variants={slideUpItem} className="coin-image-wrapper"> */}
                    <motion.div className="float-image img-coin" variants={slideUpItem}>
                        <img src={HOME1_COIN} alt="metabucket-coin-img" />
                    </motion.div>
                    <motion.div className="float-image img-coin-bucket" variants={slideUpItem}>
                        <img src={HOME1_BUCKET} alt="metabucket-coin-bucket-img" />
                    </motion.div>
                    {/* </motion.div> */}
                </div>
            </div>
        </motion.div>
    );
};

export default MainSection;
