const motionDefault = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
};

export const motionContainer = {
    hidden: { ...motionDefault.hidden },
    show: {
        ...motionDefault.show,
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.5,
        },
    },
};

export const slideUpMotion = {
    hidden: { ...motionDefault.hidden, y: 30 },
    show: { ...motionDefault.show, y: 0 },
};

export const slideUpContainer = {
    hidden: { ...slideUpMotion.hidden },
    show: {
        ...slideUpMotion.show,
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.5,
        },
    },
};

export const scaleMotion = (scale = 1) => {
    return {
        hidden: { scale: 1 },
        show: {
            scale: [1, 1.1, 1],
            time: [0, 0.8, 1],
            transition: {
                // type: "spring",
                // stiffness: 500,
                // delay: 1,
            },
        },
    };
};

export const HiddenandScaleMotion = (delay = 0) => {
    return {
        hidden: { scale: 1},
        show: {
            scale: [1, 1.1, 0.5],
            time: [0, 0.8, 1],
            opacity: [1, 1, 0],
            transition: {
                // type: "spring",
                ease: "easeOut",
                // delay: 1,
                duration: 1.5,
                delay: delay,
            },
        },
    };
};

export const scaleFadeAndHiddenMotion = (delay = 0) => {
    return {
        hidden: { scale: 0, ...motionDefault.hidden },
        show: {
            scale: [0, 1, 1, 1.1,  0 ],
            opacity: [0, 1 ,1, 1,0],
            time: [0, 0.6,0.8, 0.88, 1],
            transition: {
                // type: "spring",
                // stiffness: 500,
                ease: "easeOut",
                duration: 1.8,
                delay: delay,
            },
        },
    };
};
export const scaleFadeMotion = (delay = 0) => {
    return {
        hidden: { scale: 0, ...motionDefault.hidden },
        show: {
            scale: [0, 1.1, 1 ],
            opacity: [0, 1 , 1],
            time: [0, 0.8, 1],
            transition: {
                // type: "spring",
                // stiffness: 500,
                ease: "easeOut",
                duration: 1.5,
                delay: delay,
            },
        },
    };
};
