import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./assets/css/global.css";
import "./languages/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <RecoilRoot>
        <App />
    </RecoilRoot>
);
